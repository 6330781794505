// Generated by Framer (1d71865)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, SVG, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["I_eJ1dwtP", "ZQt3PdwoG"];

const serializationHash = "framer-6JTu1"

const variantClassNames = {I_eJ1dwtP: "framer-v-10l5dar", ZQt3PdwoG: "framer-v-3kc3xq"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const humanReadableVariantMap = {Day: "I_eJ1dwtP", Night: "ZQt3PdwoG"}

const getProps = ({height, id, width, ...props}) => { return {...props, variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "I_eJ1dwtP"} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "I_eJ1dwtP", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} {...gestureHandlers} className={cx(serializationHash, ...sharedStyleClassNames, "framer-10l5dar", className, classNames)} data-framer-name={"Day"} layoutDependency={layoutDependency} layoutId={"I_eJ1dwtP"} ref={ref ?? ref1} style={{...style}} {...addPropertyOverrides({ZQt3PdwoG: {"data-framer-name": "Night"}}, baseVariant, gestureVariant)}><SVG className={"framer-1l5tua7"} data-framer-name={"Icon"} layout={"position"} layoutDependency={layoutDependency} layoutId={"D0Q9oAbjQ"} opacity={1} style={{backgroundColor: "rgba(0, 0, 0, 0)"}} svg={"<svg xmlns=\"http://www.w3.org/2000/svg\" xmlns:xlink=\"http://www.w3.org/1999/xlink\" viewBox=\"0 0 14 14\"><path d=\"M 1.167 6.961 L 12 6.961 M 7 1.128 L 12.833 6.961 L 7 12.794\" fill=\"transparent\" stroke-width=\"1.5\" stroke=\"rgb(255,255,255)\" stroke-linecap=\"square\" stroke-miterlimit=\"10\"></path></svg>"} svgContentId={12726938898} variants={{ZQt3PdwoG: {backgroundColor: "rgba(0, 0, 0, 0)"}}} withExternalLayout {...addPropertyOverrides({ZQt3PdwoG: {svg: "<svg xmlns=\"http://www.w3.org/2000/svg\" xmlns:xlink=\"http://www.w3.org/1999/xlink\" viewBox=\"0 0 14 14\"><path d=\"M 1.167 6.961 L 12 6.961 M 7 1.128 L 12.833 6.961 L 7 12.794\" fill=\"transparent\" stroke-width=\"1.5\" stroke=\"var(--token-60b8256d-8ac8-433f-b6b8-cb9776d3a855, rgb(39, 43, 39))\" stroke-linecap=\"square\" stroke-miterlimit=\"10\"></path></svg>", svgContentId: 11587683388}}, baseVariant, gestureVariant)}/></motion.div></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-6JTu1.framer-amq98y, .framer-6JTu1 .framer-amq98y { display: block; }", ".framer-6JTu1.framer-10l5dar { height: 20px; overflow: hidden; position: relative; width: 20px; }", ".framer-6JTu1 .framer-1l5tua7 { flex: none; height: 14px; left: calc(50.00000000000002% - 14px / 2); position: absolute; top: calc(50.00000000000002% - 14px / 2); width: 14px; }", ".framer-6JTu1.framer-v-3kc3xq.framer-10l5dar { aspect-ratio: 1 / 1; height: var(--framer-aspect-ratio-supported, 20px); }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 20
 * @framerIntrinsicWidth 20
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"ZQt3PdwoG":{"layout":["fixed","fixed"]}}}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerLCrPIdMaM: React.ComponentType<Props> = withCSS(Component, css, "framer-6JTu1") as typeof Component;
export default FramerLCrPIdMaM;

FramerLCrPIdMaM.displayName = "Link Arrow";

FramerLCrPIdMaM.defaultProps = {height: 20, width: 20};

addPropertyControls(FramerLCrPIdMaM, {variant: {options: ["I_eJ1dwtP", "ZQt3PdwoG"], optionTitles: ["Day", "Night"], title: "Variant", type: ControlType.Enum}} as any)

addFonts(FramerLCrPIdMaM, [{explicitInter: true, fonts: []}], {supportsExplicitInterCodegen: true})